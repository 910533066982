@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Cairo", "Roboto", sans-serif;
  }

  [type="checkbox"],
  [type="radio"]{
    min-width: 15px;
  }
  [type="checkbox"]:checked,
  [type="radio"]:checked{
    accent-color: rgb(92 76 63);
  }

  .active-page-class{
    color: #fff !important;
    background: rgb(92 76 63) !important;
  }

  .shadow-full{
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  }

  .shadow-full-sm{
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
  }

  .shadow-full-md{
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.20);
  }

  .shadow-full-lg{
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.16);
  }
  button, input, select{
    outline: 0;
  }
}
