:root {
  --rmdp-primary-red: #27AE60;
  --rmdp-secondary-red: #7be8b5;
  --rmdp-shadow-red: #cfb6b6;
  --rmdp-today-gray: #504c4c;
  --rmdp-hover-red: #4ab376;
  --rmdp-deselect-red: #3bfa8c;
}

.default .rmdp-wrapper {
  border: 1px solid var(--rmdp-secondary-red);
  box-shadow: 0 0 5px var(--rmdp-secondary-red);
}

.default .rmdp-panel-body li {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 2px var(--rmdp-secondary-red);
}

.default .rmdp-week-day {
  color: var(--rmdp-primary-red);
}

.default .rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-red);
}

.default .rmdp-range {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 3px var(--rmdp-shadow-red);
}

.default .rmdp-arrow {
  border: solid var(--rmdp-primary-red);
  border-width: 0 2px 2px 0;
}

.default .rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 3px var(--rmdp-secondary-red);
}

.default .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-red);
}

.default .rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today-gray);
}

.default .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-red);
}

.default .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 3px var(--rmdp-shadow-red);
}

.default .rmdp-day:not(.rmdp-day-hidden):not(.rmdp-disabled) span:hover {
  background-color: var(--rmdp-hover-red) !important;
}
.default .rmdp-day:not(.rmdp-day-hidden):not(.rmdp-disabled){
  cursor: pointer;
}
.default .rmdp-day:not(.rmdp-day-hidden).rmdp-disabled{
  cursor: not-allowed;
  pointer-events: none;
}
.default .b-deselect {
  color: var(--rmdp-deselect-red);
  background-color: white;
}

.default .rmdp-action-button {
  color: var(--rmdp-primary-red);
}

.default .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--rmdp-primary-red);
}

.default .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--rmdp-deselect-red);
}
